'use client';

import { createContextualCan } from '@casl/react';
import { createContext } from 'react';

import type { AppAbilityType } from './ability';

export const AbilityContext = createContext<AppAbilityType>(undefined!);

export default createContextualCan(AbilityContext.Consumer);
